import React, { useState, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import FormattedText from '../../../01_atoms/FormattedText';

import SpeakerIcon from '../../../../public/static/icons/speaker.svg';
import BookIcon from '../../../../public/static/icons/education-and-advocacy.svg';
import GovernanceIcon from '../../../../public/static/icons/governance.svg';
import LogoWhite from '../../../../public/static/icons/logos/logo-white.svg';
import StrapLine from '../../../../public/static/straplines/ending-extreme-poverty-whatever-it-takes.svg';
import HandIcon from '../../../../public/static/icons/hand.svg';
import HeartIcon from '../../../../public/static/icons/heart.svg';

import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';
import { scrollToElement } from '../../../../utils/scroll-to';

const BBWhereMyMoneyGoes = ({
  heading = '',
  largeText,
  smallText = '',
  description = '',
  buttonLabel,
  buttonLabelCloseMobile = 'How the rest is spent',
  items,
  learnMore = {
    nextLink: null,
    label: '',
  },
  behaviorSettings = null,
  logoLink,
  uuid = null,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);
  const iconsContainerId = useId();
  useEffect(() => {
    const closeOnEsc = (event) => {
      if (panelOpen >= 0 && event.keyCode === 27) {
        setPanelOpen(false);
      }
    };
    document.addEventListener('keydown', closeOnEsc);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [panelOpen]);

  useEffect(() => {
    const checkIconVisible = (entries, observer) => {
      entries.forEach((entry) => {
        const target = entry.target.querySelectorAll('svg')[0];
        const { clientWidth } = document.body;
        const device = clientWidth < 1200 ? 'mobile' : 'desktop';
        if (entry.isIntersecting && !target.classList.contains(styles[`animation-${device}`])) {
          target.classList.add(styles[`animation-${device}`]);
          // Let animation to finish, and then remove animation classnames.
          setTimeout(() => {
            target.classList.remove(styles[`animation-mobile`]);
            target.classList.remove(styles[`animation-desktop`]);
          }, 2500);
        }
      });
    };
    const options = {
      root: null,
      rootMargin: '-20% 0px -20% 0px',
      threshold: 1,
    };

    let observer = new IntersectionObserver(checkIconVisible, options);
    // Intersection events will be executed during resize process for SVG (animation),
    // therefore we observe icons container to avoid unnecessary intersection events.
    const iconsContainer = document.getElementById(iconsContainerId);
    observer.observe(iconsContainer);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={`
      bb bb-where-my-money-goes ${styles['bb-where-my-money-goes']} ${
        panelOpen ? styles['bb-where-my-money-goes--opened'] : ''
      }  ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
      id={uuid}
    >
      <div className="container">
        <div className={styles['bb-where-my-money-goes__container']}>
          <div className={styles['bb-where-my-money-goes__logo-and-strapline-container']}>
            {logoLink && (
              <div className={styles['bb-where-my-money-goes__site-logo']}>
                <Link {...logoLink}>
                  <a tabIndex="0">
                    <LogoWhite
                      alt="Concern Worldwide"
                      className={styles['bb-where-my-money-goes__logo-image']}
                      aria-label="Concern Worldwide"
                    />
                  </a>
                </Link>
              </div>
            )}
            <StrapLine className={styles['bb-where-my-money-goes__strapline']} />
          </div>
          <div className={styles['bb-where-my-money-goes__info']}>
            <h2 className={styles['bb-where-my-money-goes__heading']}>{heading}</h2>
            <div
              id={iconsContainerId}
              className={styles['bb-where-my-money-goes__info-with-icons']}
            >
              <div className={styles['bb-where-my-money-goes__icons']}>
                <HeartIcon className={styles['bb-where-my-money-goes__heart-icon']} />
                <HandIcon className={styles['bb-where-my-money-goes__hand-icon']} />
              </div>
              <h3 className={styles['bb-where-my-money-goes__main-info']}>
                <span className={styles['bb-where-my-money-goes__large-info']}>{largeText}</span>
                <span className={styles['bb-where-my-money-goes__small-info']}>{smallText}</span>
              </h3>
            </div>
            <FormattedText
              text={description}
              className={styles['bb-where-my-money-goes__description']}
            />
            <div>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setPanelOpen(!panelOpen);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    e.preventDefault();
                    setPanelOpen(!panelOpen);
                  }
                }}
                className={`${styles['bb-where-my-money-goes__toggle']} ${styles[`bb-where-my-money-goes__toggle--${panelOpen ? 'toggled' : 'untoggled'}`]}`}
                aria-haspopup="true"
                data-analytics={`where-your-money-goes-other-percentages-${
                  panelOpen ? 'close' : 'open'
                }`}
              >
                {buttonLabel}
              </a>
            </div>
          </div>
        </div>
      </div>
      {items && !!items.length && (
        <div className="container">
          <div className={styles['bb-where-my-money-goes-panel']}>
            <div className={styles['bb-where-my-money-goes-panel__container']}>
              <ul className={styles['bb-where-my-money-goes-panel__items']}>
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={`${styles['bb-where-my-money-goes-panel__item']}`}
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <h4>
                      {index === 0 && <SpeakerIcon aria-hidden="true" />}
                      {index === 1 && <BookIcon aria-hidden="true" />}
                      {index === 2 && <GovernanceIcon aria-hidden="true" />}
                      {item.heading}
                    </h4>
                    <div>
                      <span className={styles['bb-where-my-money-goes-panel__large-text']}>
                        {item.largeText}
                      </span>
                      <span className={styles['bb-where-my-money-goes-panel__small-text']}>
                        {item.smallText}
                      </span>
                    </div>

                    <p className={styles['bb-where-my-money-goes-panel__description']}>
                      {item.description}
                    </p>
                  </li>
                ))}
              </ul>
              <div className={styles['bb-where-my-money-goes-panel__buttons']}>
                {learnMore.nextLink && (
                  <Link {...learnMore.nextLink}>
                    <a
                      className={`${styles['bb-where-my-money-goes-panel__learn-more']}`}
                      data-analytics="where-your-money-goes-learn-more"
                    >
                      {learnMore.label}
                    </a>
                  </Link>
                )}

                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setPanelOpen(!panelOpen);
                    scrollToElement({ elementId: uuid });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                      e.preventDefault();
                      setPanelOpen(!panelOpen);
                      scrollToElement({ elementId: uuid });
                    }
                  }}
                  className={`${styles['bb-where-my-money-goes__toggle']} ${styles[`bb-where-my-money-goes__toggle--${panelOpen ? 'toggled' : 'untoggled'}`]}`}
                  aria-haspopup="true"
                  data-analytics={`where-your-money-goes-other-percentages-${
                    panelOpen ? 'close' : 'open'
                  }`}
                >
                  {buttonLabelCloseMobile}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

BBWhereMyMoneyGoes.propTypes = {
  heading: PropTypes.string,
  logoLink: PropTypes.shape(linkPropTypes).isRequired,
  largeText: PropTypes.string.isRequired,
  smallText: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonLabelCloseMobile: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      largeText: PropTypes.string.isRequired,
      smallText: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  learnMore: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  uuid: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
};

export default BBWhereMyMoneyGoes;
